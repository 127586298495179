body {
  background: #ffff;

  &.compensate-for-scrollbar {
    overflow: visible;
    margin-right: 0;
  }
}

.body-wrapper {
  transition: none;
}

@color1: #e27500;

.btn-color {
  background-color: @color1;
  border-color: @color1;
  padding: 7px 20px;
  font-size: 15px;
  letter-spacing: 1px;
  color: #fff;
  transition: all 350ms;
  border-radius: 0;

  &:hover {
    background-color: #ca6800;
    color: #fff;
  }
}

.btn-grey {
  background-color: #e4e4e4;
  padding: 7px 20px;
  font-size: 15px;
  letter-spacing: 1px;
  color: #000;
  transition: all 350ms;
  border-radius: 0;
  transition: background-color 350ms;

  &:hover {
    background-color: #cacaca;
  }
}

#return-to-top {
  position: fixed;
  z-index: 333;
  border: 1px solid #ddd;
  bottom: 30px;
  right: 50px;
  background-color: rgba(@color1, 0.9);
  width: 35px;
  height: 35px;
  display: block;
  text-decoration: none;
  display: none;
  transition: all 0.3s ease;
  cursor: pointer;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      width: 20px;
      transform: rotate(-90deg);
    }
  }

  &:hover {
    background-color: @color1;
  }
}

nav.section-header {
  transition: all 0.6s;

  &.hide {
    opacity: 0;
    transform: translateY(-20px);
  }

  &.hidden-nav {
    opacity: 0;
    visibility: hidden;
  }

  &.fixed {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    z-index: 333;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    background-color: #fff;

    &.active {
      opacity: 1;
      visibility: visible;
      animation: fixed-nav 450ms ease;
    }

    &.hide {
      transform: translateY(-90px);
    }

    @keyframes fixed-nav {
      from {
        top: -75px;
      }

      to {
        top: 0;
      }
    }

    .header-management {
      padding: 10px 0;

      .management-logo {
        &.mobile {
          display: none;
        }
        img {
          width: 220px;
        }
      }
    }
  }

  .header-top {
    overflow: hidden;

    .management-search-engine {
      border: 0.5px solid #000;
      height: 40px;
      border-radius: 0;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.4s;

      input {
        width: 100%;
        width: 350px;
        height: 40px;
        padding: 10px 15px 10px 15px;
        border: none;
        background: none;
        font-size: 11px;
        color: #000;
        font-weight: 500;
        letter-spacing: 1px;
        margin: 0;
        transition: all 0.4s;

        &:focus {
          outline: none;
        }

        &::placeholder {
          font-size: 12px;
          color: #000;
          opacity: 0.5;
          font-weight: 500;
          letter-spacing: 1px;
        }
      }

      .btn-search {
        height: 40px;
        min-width: 40px;
        background: #000;
        border: none;
        border-radius: 0;
        font-size: 14px;
        color: #fff;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(1px);
        transition: background 0.4s;

        svg {
          height: 15px;
          width: 15px;
          fill: #fff;
        }

        &:hover {
          background: darken(#000, 10%);
        }
      }
    }

    .top-navigation {
      &.top-contact {
        position: relative;
        padding: 0 10px;

        &:before {
          position: absolute;
          z-index: -1;
          content: "";
          background-color: @color1;
          top: -47px;
          right: -70%;
          width: 175%;
          height: 100px;
        }
      }

      li {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: #fff;

        a {
          color: #fff;
          font-size: 13px;
          letter-spacing: 0.5px;
        }
      }
    }
  }

  .header-management {
    .management-links {
      display: flex;

      li {
        margin-right: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;

        &.management-offer {
          position: relative;

          button {
            height: auto;
            border-radius: 0;
            padding: 0;
            color: #000;
            background-color: transparent;

            &.active {
              color: @color1;
            }

            span {
              font-size: 14px;
              font-weight: 400;
              text-transform: none;
            }

            svg {
              fill: #000;
            }
          }

          .offer-dropdown {
            .offer-dropdown-inner {
              .dropdown-item {
                .item-heading {
                  font-size: 14px;
                  padding: 15px 20px;
                }
              }
            }
          }
        }

        a {
          color: #000;
          transition: color 350ms;

          &.active {
            color: @color1;
          }
        }

        &:hover {
          a {
            color: @color1;
          }
        }
      }
    }

    .management-service {
      .service-icons {
        a,
        button {
          span {
            font-size: 12px;
            font-weight: 400;
            color: #000;
            display: inline-block;
            margin-right: 5px;
          }

          .quantity-badge {
            height: 18px;
            width: 18px;
            font-size: 9px;
            font-weight: 400;
            left: 13px;
          }
        }

        &:nth-child(3) {
          margin-left: 20px;

          a {
            .quantity-badge {
              left: 13px;
            }
          }
        }
      }
    }
  }
}

section.section-welcome {
  .owl-carousel-welcome,
  .owl-carousel-welcome-mobile {
    transition: all 400ms;

    &.hide {
      opacity: 0;
      transform: translateY(-20px);
    }
  }

  .welcome-item {
    .item-image {
      width: 50%;
    }

    .item-description {
      width: 45%;

      .text {
        max-width: 600px;
        font-size: 42px;
        line-height: 52px;
        font-weight: 500;
      }

      .description {
        max-width: 600px;
      }

      .info {
        font-size: 11px;
      }

      &:before {
        left: -200px;
      }
    }
  }
}

.section-heading {
  text-align: center;

  h2 {
    font-size: 34px;
    font-weight: 400;
    color: #000;
  }
}

section.section-categories-project {
  position: relative;
  padding: 80px 0 120px;
  transition: all 0.6s;

  &.hide {
    opacity: 0;
    transform: translateY(-20px);
  }

  .category-wrapper {
    background-color: #fff;

    .category-elements {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      gap: 40px;

      .category-element {
        position: relative;
        width: 23%;
        padding-top: 23%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #f3f3f3;

        .image-cover {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(0, 0, 0, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: all 0.4s;

          .cover-inner {
            height: 43px;
            width: 43px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: 16px;
              width: 16px;
            }
          }
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 95%;
          height: 95%;
          object-fit: cover;
          transition: opacity 350ms;
        }

        .caption {
          position: absolute;
          z-index: 11;
          bottom: -10px;
          width: 70%;
          color: #fff;
          background-color: @color1;
          padding: 10px;
          transition: background-color 350ms;
          text-align: center;
          margin-top: 15px;

          h3 {
            font-weight: 400;
            letter-spacing: 1px;
            font-size: 14.5px;
          }
        }

        &:hover {
          .image-cover {
            opacity: 1;
          }

          img {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

section.section-offer {
  margin: 80px 0;

  &.subpage {
    margin: 0;
  }
}

section.section-offer-project {
  margin-top: 80px;
  padding: 80px 0;
  background-color: #f3f3f3;
  transition: all 0.4s;

  .offer-wrapper {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;

    .offer-element {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 23.5%;
      color: #000;

      h3 {
        font-size: 20px;
        margin-bottom: 20px;
      }

      img {
        width: 70%;
        transition: opacity 350ms;
      }

      .caption {
        min-height: 230px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        color: #000;
        text-align: center;

        h3 {
          font-size: 20px;
          margin-bottom: 20px;
        }

        p {
          font-size: 14.5px;
          line-height: 24px;
        }
      }

      .btn-color {
        margin-top: 20px;
      }

      &:hover {
        img {
          opacity: 0.5;
        }

        .btn-color {
          background-color: #ca6800;
        }
      }
    }
  }
}

section.section-cta-project {
  position: relative;
  padding: 80px 0;
  transition: all 0.4s;

  .cta-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url(../img/backgrounds/pattern.svg) center;
    background-size: cover;
    background-attachment: fixed;
    opacity: 0.1;
  }

  .cta-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cta-text {
      background-color: #fff;
      width: 60%;

      h2 {
        font-size: 38px;
        line-height: 48px;
        color: #000;

        span {
          font-size: 28px;
          display: inline-block;
          margin-left: 60px;
          color: @color1;
        }
      }
    }

    .cta-buttons {
      width: 40%;

      a {
        display: inline-block;
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: 1px;
        color: @color1;

        span {
          color: #000;
          font-size: 28px;
        }

        &:first-of-type {
          margin-left: -50px;
        }
      }
    }
  }
}

section.section-special-offer-project {
  transition: all 0.4s;

  .special-offer-wrapper {
    display: flex;
    justify-content: space-between;

    .offer-image {
      width: 55%;
      padding-top: 28%;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .offer-text {
      width: 45%;
      background-color: @color1;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 0 100px 0 40px;
      text-align: left;

      h2 {
        font-size: 42px;
      }

      span {
        font-size: 24px;
        display: block;
        margin-bottom: 20px;
      }

      .btn-grey {
        margin-top: 30px;
      }
    }
  }
}

section.section-realizations-project {
  margin-top: 80px;
  transition: all 0.4s;

  &.subpage {
    margin: 0 0 80px;
  }

  .section-heading {
    text-align: center;

    h1,
    h2 {
      font-size: 34px;
      font-weight: 400;
      color: #000;

      a {
        color: inherit;
      }
    }

    p {
      margin-top: 10px;
      opacity: 0.7;
      color: #000;
      font-size: 13px;
      letter-spacing: 0.3px;
    }
  }

  .realization-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .realization-service {
      margin-top: 3.5%;
      width: 23.5%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      transition: all 0.4s;

      .service-image-wrapper {
        width: 100%;
        padding-top: 100%;
        position: relative;
        display: block;
        transition: all 0.4s;
        position: relative;

        .image-cover {
          position: absolute;
          z-index: 121;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(0, 0, 0, 0.2);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: all 0.4s;

          .cover-inner {
            height: 43px;
            width: 43px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: 16px;
              width: 16px;
            }
          }
        }

        .service-image {
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          overflow: hidden;
          background: #f3f3f3;

          img {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            object-position: center;
            padding: 8px;
          }
        }
      }

      .date {
        .small-letters(#000);
        margin-top: 15px;
        font-size: 11px;
        display: block;

        &.list-view {
          display: none;
        }
      }

      .name {
        margin-top: 3px;
        color: #000;
        font-weight: 500;
        font-size: 16px;
        padding-right: 20px;
        display: block;
        transition: all 0.4s;

        &.list-view {
          display: none;
        }
      }

      &:hover {
        .service-image-wrapper {
          .image-cover {
            opacity: 1;
          }
        }

        .name {
          text-decoration-line: underline !important;
          text-decoration-style: solid !important;
          text-decoration-color: #000 !important;
          text-decoration-thickness: 0.5px !important;
        }
      }

      &.service-hidden {
        margin: 0 !important;
        padding: 0 !important;
        border: none !important;
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}

section.section-about-project {
  background-color: #f3f3f3;
  position: relative;
  padding: 80px 0 100px;
  margin-top: 100px;
  transition: all 0.4s;

  .about-inner {
    text-align: center;
    padding: 0 10%;
  }

  .section-heading {
    font-size: 30px;
    text-align: center;
    color: #000;
    font-weight: 500;
    margin-bottom: 20px;
  }

  p {
    opacity: 0.7;
    color: #000;
    font-size: 13px;
    letter-spacing: 0.3px;
    margin-top: 20px;
  }

  .btn-color {
    margin-top: 30px;
  }
}

.products-flexbox {
  .product-service {
    .service-image-wrapper {
      border: 1px solid #ccc;
    }

    .info {
      font-size: 11px;
    }

    .feature {
      font-size: 11px;
    }
  }
}

.select-wrapper {
  border-radius: 1px;

  select {
    border-radius: 1px;
  }
}

.category-nav-wrapper {
  .category-nav {
    li {
      ul {
        li {
          a {
            padding: 8px 0;
          }
        }
      }
    }
  }

  .filter-nav {
    .range-wrapper {
      input[type="number"] {
        border-radius: 1px;
      }
    }
  }
}

section.kategoria-section-content {
  padding: 40px 0;

  .content-description {
    background-color: #f3f3f3;
    border: none;
    padding: 80px 0;
  }

  .content-flexbox {
    .flexbox-body {
      .body-heading {
        .inner-wrapper {
          .heading-grid-wrapper {
            .heading-grid {
              border-radius: 1px;
            }
          }
        }
      }
    }
  }

  .pagination-nav {
    margin: 40px 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    li {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        border-radius: 50px;
        color: #222;
        text-transform: uppercase;
        transition: all 0.3s;
      }

      &:hover {
        a {
          color: @color1;
        }
      }

      &.active {
        a {
          font-weight: 600;
          color: #fff;
          background: @color1;
        }
      }
    }
  }
}

section.produkt-section-content {
  padding: 40px 0 60px;
}

section.o-firmie-section-content,
section.static-page-section-content {
  transition: all 0.4s;
  padding: 0 0 80px;

  p,
  li {
    opacity: 0.7;
    color: #000;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.3px;
    backface-visibility: hidden;
  }

  li {
    margin-top: 10px;
  }

  p:not(:first-of-type) {
    margin-top: 15px;
  }

  h3 {
    padding-top: 20px;
    font-size: 24px;
    font-weight: 400;
  }

  ul,
  ol {
    padding: 10px 0 0 17px;
  }

  .section-heading {
    padding: 20px 0 35px 0;
    border-bottom: 0.5px solid #ccc;
    text-align: left;

    h2 {
      font-size: 34px;
      font-weight: 400;
      backface-visibility: hidden;
    }
  }

  .section-content {
    padding-top: 30px;
  }

  .content-image {
    height: 400px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .btn-wrapper {
    padding: 40px 0;
    margin: 60px auto 0;
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid @color1;
    background-color: #f3f3f3;

    h3 {
      font-size: 24px;
      font-weight: 400;
      backface-visibility: hidden;
    }

    .btn-fill,
    .btn-color {
      margin-top: 30px;
    }
  }

  .inner-category-nav {
    padding: 40px 0;
    margin-top: 60px;
    background-color: #f3f3f3;

    h2 {
      font-size: 34px;
      font-weight: 400;
      color: #000;
      text-align: center;
    }

    .nav-tabs {
      margin-top: 20px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      border: none;

      .nav-item {
        &:hover {
          .nav-link {
            background-color: darken(@color1, 10%) !important;
          }
        }

        .nav-link {
          padding: 10px 20px;
          margin: 0 20px;
          border: none;
          border-radius: 0;
          background-color: @color1;
          color: #fff;
          transition: 0.4s;
          font-size: 14.5px;
          line-height: 24px;
          font-weight: 400;
          text-transform: none;
        }
      }
    }
  }

  .accordion {
    .accordion-body {
      p,
      li {
        color: #000;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.3px;
        opacity: 1;
      }
    }
  }
}

section.kontakt-section-content {
  padding: 0 0 60px;

  .content-inner {
    padding-top: 60px;
  }
}
