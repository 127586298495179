@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  min-height: 100vh;
  height: 100%;
  font-family: "Work Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f9f9f9;
  position: relative;

  &.no-js {
    .intro {
      opacity: 1;
      transition: none;
    }
  }

  .intro {
    opacity: 0;
    transform: translateY(-20px);
  }

  &.no-scroll {
    overflow: hidden;
    max-height: 100vh;
  }

  .btn {
    &.btn-default {
      padding: 0;
      border-radius: 0;
    }

    .btn-inner {
      display: flex;
      align-items: center;
    }

    .inner-icon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }

    .inner-text {
      .small-letters(inherit);
    }

    &.pulse-animate {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transform: scale(0.8);
        display: block;
        width: 20px;
        height: 20px;
        background-color: @color1;
        filter: blur(2px);
        border-radius: 15px;
        animation: pulse-dot 5s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
      }
    }

    @keyframes pulse-dot {
      40% {
        transform: scale(0.8);
        opacity: 0;
      }
      50% {
        opacity: 0.3;
        transform: scale(1);
      }
      60% {
        opacity: 0;
        transform: scale(2);
      }
      100% {
        opacity: 0;
        transform: scale(2);
      }
    }
  }

  .btn-border {
    .small-letters(#000);
    padding: 10px 30px;
    border: 1px solid #000;
    border-radius: 2px;
    display: inline-block;
    outline: none;
    background: none;
  }

  .btn-empty {
    .small-letters(#000);
    padding: 0;
    border: none;
    display: inline-block;
    outline: none;
    background: none;
    transition: all 0.4s;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn-fill {
    .small-letters(#fff);
    padding: 10px 30px;
    border: 1px solid #000;
    border-radius: 2px;
    background: #000;
    display: inline-block;
    outline: none;

    &.btn-icon {
      display: flex;
      align-items: center;
      transition: all 0.4s;

      svg {
        min-width: 15px;
        max-width: 15px;
        fill: #fff;
        margin-right: 10px;
      }

      span {
        white-space: nowrap;
      }
    }

    &.btn-small {
      padding: 5px 20px;
    }
  }

  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }
}

@color1: #c31432;
@color2: #e27500;

.small-letters(@color) {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: @color;
}

.color-default {
  color: @color1 !important;

  &.color-default-hover {
    &:hover {
      color: darken(@color1, 10%) !important;
    }
  }
}

.color-default-hover {
  transition: all 0.4s;

  &:hover {
    color: @color1 !important;
  }
}

.background-default {
  background-color: @color1 !important;

  &.background-default-hover {
    &:hover {
      background-color: darken(@color1, 10%) !important;
    }
  }
}

.background-default-hover {
  transition: all 0.4s;

  &:hover {
    background-color: @color1 !important;
  }
}

.border-default {
  border-color: @color1 !important;

  &.border-default-hover {
    &:hover {
      border-color: darken(@color1, 10%) !important;
    }
  }
}

.border-default-hover {
  transition: all 0.4s;

  &:hover {
    border-color: @color1 !important;
  }
}

.fill-default {
  fill: @color1 !important;

  &.fill-default-hover {
    &:hover {
      fill: darken(@color1, 10%) !important;
    }
  }
}

.fill-default-hover {
  transition: all 0.4s;

  &:hover {
    fill: @color1 !important;
  }
}

.toast-container {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 999;

  .toast {
    z-index: 999;
    position: relative;
    left: 0;
    bottom: 0;
    border: none;

    .toast-header {
      border-radius: 2px 2px 0 0;

      &.success {
        background: olivedrab;
      }

      &.warning {
        background: crimson;
      }
    }

    .toast-body {
      background: #fff;
      border-radius: 0 0 2px 2px;
    }
  }
}

.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .modal-service {
    padding: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;

    .service-inner {
      background: #fff;
      width: 700px;
      max-width: 100%;
      padding: 50px 25px 25px 25px;
      text-align: center;
      position: relative;

      .service-button {
        position: absolute;
        top: 20px;
        right: 20px;
        border: none;
        background: none;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-bar {
          display: block;
          position: absolute;
          width: 20px;
          height: 2px;
          background: #222;

          &:nth-child(1) {
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }
        }
      }

      .service-content {
        .heading {
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        .btn {
          display: flex;
          justify-content: center;
          margin-top: 5px;
        }
      }

      .service-buttons {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-link {
          padding: 0;
          text-transform: uppercase;
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}

.breadcrumb-wrapper {
  padding: 0 0 30px 0;
  margin: 0;
  transition: all 0.4s;

  .breadcrumb {
    border-radius: 0;
    padding: 0;
    background: none;
    margin-bottom: 0;
    display: flex;

    .breadcrumb-item {
      font-size: 12px;
      font-weight: 400;
      color: #999;

      a {
        transition: all 0.4s;
      }
    }

    .breadcrumb-item + .breadcrumb-item::before {
      font-weight: 200;
    }
  }
}

.checkbox-wrapper {
  cursor: pointer;
  display: block;
  margin: 0;

  .radio-box {
    display: flex;
    font-size: 12px;
    color: #000;
    letter-spacing: 0.5px;
    font-weight: 400;
    text-align: left;
    transition: all 0.3s;

    a {
      text-decoration: underline;
    }

    .box-square {
      content: "";
      min-width: 12px;
      max-width: 12px;
      height: 12px;
      display: block;
      border: 2px solid #999;
      margin-top: 3px;
      margin-right: 10px;
      transition: all 0.3s;
    }
  }

  input {
    visibility: hidden;
    position: absolute;
    width: auto !important;
  }
}

.select-wrapper {
  position: relative;
  border: 1px solid #999;
  border-radius: 30px;
  overflow: hidden;

  select {
    cursor: pointer;
    padding: 6px 100px 5px 15px;
    border: none;
    border-radius: 50px;
    background: #fff;
    height: auto;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    display: block;
    .small-letters(#000);
    font-weight: 400;
    font-size: 11px;
    width: 100%;
  }

  svg {
    position: absolute;
    fill: #000;
    height: 6px;
    min-width: 6px;
    max-width: 6px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
  }
}

.products-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .product-service {
    margin-top: 3.5%;
    width: 23.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: all 0.4s;

    .badge-wrapper {
      position: absolute;
      z-index: 111;
      top: 7px;
      left: 7px;
      width: 100%;

      .badge {
        width: 90px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 10px;
        color: #fff;
        border-radius: 0;
        margin-bottom: 6px;

        &.promotion {
          background: tomato;
          display: none;
        }

        &.new {
          background: olivedrab;
          display: none;
        }

        &.bestseller {
          background: dodgerblue;
          display: none;
        }

        &.own {
          background: #ef7b0a;
          display: none;
        }
      }
    }

    .service-favorite {
      position: absolute;
      top: 7px;
      right: 7px;
      z-index: 222;
      display: flex;
      padding: 5px;
      border: none;
      background: #fff;
      border-radius: 2px;
      outline: none;

      svg {
        height: 15px;
        width: 15px;
        transition: all 0.4s;

        path {
          transition: all 0.4s;

          &:nth-child(1) {
            fill: #fff;
          }
        }
      }

      &.checked,
      &:hover {
        svg {
          path {
            &:nth-child(1) {
              fill: #e9b9b9;
            }
          }
        }
      }
    }

    .service-image-wrapper {
      width: 100%;
      padding-top: 160%;
      position: relative;
      display: block;
      transition: all 0.4s;
      position: relative;
      background: #f3f3f3;

      .image-cover {
        position: absolute;
        z-index: 121;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.4s;

        .cover-inner {
          height: 43px;
          width: 43px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 16px;
            width: 16px;
          }
        }
      }

      .service-image {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;

        img {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: contain;
          object-position: center;
        }
      }

      &.square {
        padding-top: 100%;
      }

      &.cover {
        .service-image {
          img {
            object-fit: cover;
          }
        }
      }
    }

    .info {
      .small-letters(#000);
      font-size: 10px;
      text-align: center;
      display: block;

      &.list-view {
        display: none;
      }
    }

    .name {
      text-align: center;
      margin-top: 15px;
      color: #000;
      font-weight: 500;
      font-size: 14.5px;
      display: block;
      transition: all 0.4s;

      &.list-view {
        display: none;
      }
    }

    .price {
      text-align: center;
      margin-top: 10px;
      color: #000;
      font-size: 18px;
      letter-spacing: -0.5px;
      font-weight: 400;

      small {
        font-size: 18px;
        font-weight: 400;
        color: #999;
        margin-right: 7px;
        position: relative;
        display: none;

        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 1px;
          width: 100%;
          height: 0.5px;
          background: #666;
        }
      }

      span {
        font-weight: 400;
        color: #999;
        font-size: 12px;
      }
    }

    &.promotion {
      .promotion {
        display: flex !important;
      }

      .price {
        color: tomato;

        small {
          display: inline-block;
        }
      }
    }

    &.new {
      .new {
        display: flex !important;
      }
    }

    &.bestseller {
      .bestseller {
        display: flex !important;
      }
    }

    &.own {
      .own {
        display: flex !important;
      }
    }

    &:hover {
      .service-image-wrapper {
        .image-cover {
          opacity: 1;
        }
      }

      .name {
        text-decoration-line: underline !important;
        text-decoration-style: solid !important;
        text-decoration-color: #000 !important;
        text-decoration-thickness: 0.5px !important;
      }
    }

    &.service-hidden {
      margin: 0 !important;
      padding: 0 !important;
      border: none !important;
      visibility: hidden;
      opacity: 0;
    }
  }

  &.grid-list {
    .product-service {
      width: 100% !important;
      flex-direction: row;
      justify-content: flex-start;

      header {
        min-width: 17%;
        margin-right: 15px;
      }

      .info {
        margin-top: 0;
        text-align: left;

        &.grid-view {
          display: none;
        }

        &.list-view {
          display: block;
        }
      }

      .name {
        margin-top: 0;
        text-align: left;

        &.grid-view {
          display: none;
        }

        &.list-view {
          padding-right: 50px;
          display: block;
        }
      }

      .price {
        text-align: left;
      }

      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }
}

.news-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .news-service {
    margin-top: 3.5%;
    width: 31%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: all 0.4s;

    .service-image-wrapper {
      width: 100%;
      padding-top: 100%;
      position: relative;
      display: block;
      transition: all 0.4s;
      position: relative;
      background: #f3f3f3;

      .image-cover {
        position: absolute;
        z-index: 121;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.4s;

        .cover-inner {
          height: 43px;
          width: 43px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 16px;
            width: 16px;
          }
        }
      }

      .service-image {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;

        img {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .date {
      .small-letters(#000);
      margin-top: 15px;
      font-size: 11px;
      display: block;

      &.list-view {
        display: none;
      }
    }

    .name {
      margin-top: 3px;
      color: #000;
      font-weight: 500;
      font-size: 16px;
      padding-right: 20px;
      display: block;
      transition: all 0.4s;

      &.list-view {
        display: none;
      }
    }

    &:hover {
      .service-image-wrapper {
        .image-cover {
          opacity: 1;
        }
      }

      .name {
        text-decoration-line: underline !important;
        text-decoration-style: solid !important;
        text-decoration-color: #000 !important;
        text-decoration-thickness: 0.5px !important;
      }
    }

    &.service-hidden {
      margin: 0 !important;
      padding: 0 !important;
      border: none !important;
      visibility: hidden;
      opacity: 0;
    }
  }
}

.body-wrapper {
  transition: all 0.4s;

  &.active {
    transform: translateX(-620px);
    filter: blur(5px);
  }
}

.header-live {
  transition: all 0.4s;

  .alert {
    padding: 0;
    border-radius: 0;
    border: none;
    margin: 0;
    background: #000;
    text-align: center;

    .alert-inner {
      font-size: 9.2px;
      padding: 7px 25px;
      color: #fff;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
      position: relative;

      .btn-close {
        outline: none;
        box-shadow: none;
        height: 12px;
        width: 12px;
        transition: opacity 0.4s;
        border: none;
        background: none;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        background: none;
        padding: 0;
        cursor: pointer;

        span {
          position: absolute;
          display: block;
          left: 0;
          top: 50%;
          width: 12px;
          height: 1px;
          background: #fff;

          &:nth-child(1) {
            transform: rotate(45deg) translateY(-50%);
          }

          &:nth-child(2) {
            transform: rotate(-45deg) translateY(-50%);
          }
        }

        &:after {
          content: none;
        }
      }
    }
  }
}

.header-bag-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  transition: all 0.4s;

  .sidebar-service {
    position: absolute;
    height: 100%;
    background: #fff;
    right: 0;
    top: 0;
    box-shadow: -10px 5px 35px 0px rgba(0, 0, 0, 0.15);
    transform: translateX(100%);
    transition: all 0.4s;

    .service-heading {
      padding: 40px;
      min-height: 116px;
      display: flex;
      justify-content: space-between;
      color: #000;

      .heading {
        font-size: 24px;
        line-height: 28px;
        font-weight: 400;
      }

      .delivery {
        text-align: right;
        font-size: 13px;
        line-height: 18px;
      }
    }

    .service-inner {
      padding: 0 40px 40px 40px;
      width: 620px;
      max-height: calc(~"100vh - 285px");

      .product-service-wrapper {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        transition: all 0.4s;

        .product-service {
          display: flex;
          align-items: center;
          width: 100%;
        }

        .service-image-wrapper {
          min-width: 95px;
          max-width: 95px;
          padding-top: 95px * 1.6;
          position: relative;
          padding: 0;
          border: none;
          outline: none;
          display: block;
          transition: all 0.4s;
          background: #f3f3f3;

          .service-image {
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            overflow: hidden;

            img {
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              object-fit: contain;
              object-position: center;
            }
          }

          &.square {
            padding-top: 95px;
          }

          &.cover {
            .service-image {
              img {
                object-fit: cover;
              }
            }
          }
        }

        .service-name {
          width: 100%;
          padding-left: 20px;

          .info {
            .small-letters(#000);
            letter-spacing: 0;
            font-size: 10px;
            display: block;
            margin-top: 10px;
            padding: 0;
            text-align: left;
            border: none;
            background: none;
            outline: none;

            & + .info {
              margin-top: 0;
            }
          }

          .name {
            color: #000;
            font-weight: 500;
            font-size: 13px;
            display: block;
            padding: 0;
            border: none;
            background: none;
            outline: none;
            text-align: left;
            transition: all 0.4s;
          }
        }

        .breakpoint {
          display: none;
        }

        .price {
          min-width: 75px;
          max-width: 75px;
          margin-left: 20px;
          color: #000;
          font-size: 13px;
          letter-spacing: -0.5px;
          font-weight: 400;

          span {
            font-weight: 400;
            color: #999;
            font-size: 11px;
          }
        }

        .service-quantity {
          margin-left: 20px;
          max-width: 80px;
          min-width: 80px;
          display: flex;
          align-self: center;

          .input-number {
            width: 35px;
            padding: 0;
            margin: 0;
            text-align: center;
            outline: none;
            background: none;
            border-radius: 0;
            border: none;
          }

          .input-number,
          .input-number-decrement,
          .input-number-increment {
            height: 20px;
            user-select: none;
          }

          .input-number-decrement,
          .input-number-increment {
            min-width: 20px;
            border-radius: 2px;
            background: #f3f3f3;
            color: #000;
            text-align: center;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background 0.4s;
            cursor: pointer;
            border: none;
            outline: none;

            &:active {
              background: #000;
              color: #fff;
            }
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            margin: 0;
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }
        }

        .service-delete {
          padding: 0;
          background: none;
          border: none;
          display: flex;
          min-width: 15px;
          max-width: 15px;
          outline: none;
          margin-left: 20px;

          svg {
            width: 100%;
          }
        }

        &:hover {
          .name {
            text-decoration-line: underline !important;
            text-decoration-style: solid !important;
            text-decoration-color: #000 !important;
            text-decoration-thickness: 0.5px !important;
          }
        }
      }
    }

    @z-index-value: 222;

    .service-menu-wrapper {
      position: absolute;
      background: #fff;
      top: 0;
      left: 0;
      transition: all 0.6s;
      z-index: @z-index-value;
      height: 100vh;
      width: 100%;
      // box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.2);

      &.hidden {
        transform: translateX(100%);
        z-index: 1;
      }
    }

    .service-menu-wrapper + .service-menu-wrapper {
      z-index: @z-index-value * 2;
    }

    .service-menu {
      max-height: 100vh;

      .backlink {
        display: block;
        outline: none;
        padding: 15px 20px;
        width: 100%;
        background: none;
        border: none;

        .backlink-inner {
          display: flex;
          align-items: center;

          span {
            color: #000;
            font-weight: 500;
            font-size: 15px;
          }

          img {
            margin-right: 15px;
            transform: rotate(180deg);
            height: 10px;
          }
        }

        &.backlink-close {
          position: absolute;
          top: 0;
          left: 0;
          background: #fff;

          &.hidden {
            display: none;
          }
        }
      }

      .menu-filters-button {
        border: none;
        border-top: 45px solid #f1f1f1;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: none;
        outline: none;
        font-weight: 500;
        padding: 15px 20px;

        svg {
          margin-right: 10px;
          min-width: 14px;
          max-width: 14px;
          height: 14px;
          fill: #000;
        }

        span {
          font-size: 18px;
          font-weight: 500;
          color: #000;
        }

        &.hidden {
          display: none;
        }
      }

      .menu-heading {
        border-top: 45px solid #f1f1f1;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        padding: 15px 20px;
        color: #fff;
      }

      .menu-list {
        list-style: none;
        border-top: 45px solid #f1f1f1;

        li {
          a,
          button {
            padding: 15px 50px 15px 20px;
            width: 100%;
            background: none;
            position: relative;
            border: none;
            outline: none;
            display: flex;
            align-items: center;

            .icon {
              fill: #000;
              height: 20px;
              min-width: 20px;
              max-width: 20px;
              margin-right: 10px;
            }

            span {
              color: #000;
              font-weight: 500;
              font-size: 15px;
            }

            &.has-child {
              &:after {
                content: "";
                height: 10px;
                min-width: 10px;
                max-width: 10px;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                background: url(../img/icons/right-chevron.svg) center;
                background-size: cover;
              }
            }

            &.background-default {
              .icon {
                fill: #fff;
              }

              span {
                color: #fff;
              }
            }
          }

          &:not(:last-child) {
            border-bottom: 0.5px solid #ccc;
          }
        }
      }

      .menu-filters-button:not(.hidden) + .menu-heading {
        border-top: none;
      }

      .menu-heading + .menu-list {
        border-top: 0.5px solid #ccc;
      }

      .category-nav-wrapper {
        border-top: 45px solid #f1f1f1;
        padding: 20px;

        .filter-nav {
          margin-top: 0;

          .heading {
            margin-bottom: 20px;
            color: #000;
            font-weight: 500;
            font-size: 15px;

            &:not(:first-of-type) {
              margin-top: 30px;
            }
          }

          .checkbox-wrapper {
            .radio-box {
              font-size: 17px;
              letter-spacing: 1px;

              .box-square {
                margin-top: 7px;
              }
            }
          }

          .range-wrapper {
            justify-content: flex-start;

            input[type="number"] {
              margin: 0 10px;
            }
          }
        }

        .inner-footer {
          padding-top: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > * {
            & + * {
              margin-left: 20px;
            }
          }
        }
      }
    }

    .service-summary {
      bottom: 81px;
      right: 0;
      width: 100%;
      position: absolute;
      background: #fff;
      border-top: 1px solid #ccc;
      margin-top: 30px;
      color: #000;
      font-size: 17px;
      line-height: 24px;
      text-align: right;
      padding: 20px 42px 20px 40px;
    }

    .service-footer {
      position: absolute;
      bottom: 0;
      padding: 0 40px 40px 40px;
      background: #fff;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .product-modal {
      opacity: 0;
      visibility: hidden;
      transition: all 0.4s;
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      max-height: 100vh;
      height: 100%;
      width: 100%;

      .modal-inner {
        padding: 40px;
        max-height: 100%;
        opacity: 0;
        visibility: hidden;
        transform: scale(0.9);
        transition: all 0.4s;

        .inner-heading {
          display: flex;
          justify-content: space-between;
          color: #000;

          .heading {
            font-size: 20px;
            line-height: 25px;
            font-weight: 400;
            padding-right: 15px;
          }

          .btn-modal-close {
            height: 25px;
            min-width: 25px;
            max-width: 25px;
            border: none;
            background: none;
            position: relative;
            outline: none;
            outline: none;
            padding: 0;
            cursor: pointer;

            span {
              position: absolute;
              display: block;
              left: 0;
              top: 50%;
              width: 25px;
              height: 1px;
              background: #000;

              &:nth-child(1) {
                transform: rotate(45deg) translateY(-50%);
              }

              &:nth-child(2) {
                transform: rotate(-45deg) translateY(-50%);
              }
            }
          }
        }

        .service-image-wrapper-outer {
          margin-top: 50px;
          max-width: 380px;
          width: 100%;

          .service-image-wrapper {
            width: 100%;
            padding-top: 160%;
            position: relative;
            display: block;
            transition: all 0.4s;
            position: relative;
            background: #f3f3f3;

            .service-image {
              text-align: center;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              overflow: hidden;

              img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                object-fit: contain;
                object-position: center;
              }
            }

            &.square {
              padding-top: 100%;
            }

            &.cover {
              .service-image {
                img {
                  object-fit: cover;
                }
              }
            }
          }
        }

        .inner-features {
          margin-top: 50px;

          .feature-inner {
            display: flex;

            p {
              text-transform: uppercase;
              opacity: 0.7;
              color: #000;
              font-size: 13px;
              letter-spacing: 0.3px;

              &:nth-child(2) {
                margin-left: 20px;
                font-weight: 700;
              }
            }
          }
        }

        .inner-description {
          margin-top: 45px;

          p {
            opacity: 0.7;
            color: #000;
            font-size: 13px;
            letter-spacing: 0.3px;
          }
        }

        .inner-footer {
          padding-top: 40px;
          display: flex;
          align-items: center;

          > * {
            & + * {
              margin-left: 20px;
            }
          }
        }

        &.active {
          transform: none;
          opacity: 1;
          visibility: visible;
        }
      }

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }

    &.active {
      transform: translateX(0);
    }
  }

  &.favorite {
    .sidebar-service {
      .service-inner {
        max-height: calc(~"100vh - 236px");
      }

      .service-footer {
        padding-top: 40px;
        border-top: 1px solid #ccc;
      }
    }
  }

  &.active {
    z-index: 555;
    visibility: visible;
    opacity: 1;
  }
}

nav.section-header {
  transition: all 0.4s;

  .header-top {
    padding: 13px 0;
    border-bottom: 0.5px solid #ccc;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 9.2px;
      color: #000;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .nav-button {
      padding: 10px;
      border: none;
      background: none;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      outline: none;
      display: none;

      .button-bar {
        height: 2px;
        width: 24px;
        background: #000;
        border-radius: 10px;
        transition: all 0.4s;

        &:nth-child(2) {
          margin-top: 4px;
          width: 27px;
        }

        &:nth-child(3) {
          margin-top: 4px;
          width: 30px;
        }
      }

      &:hover,
      &:focus {
        .button-bar {
          width: 30px !important;
        }
      }
    }

    .top-service {
      display: flex;
      align-items: center;
    }

    .top-social-media {
      display: flex;
      align-items: center;

      li {
        a {
          svg {
            height: 16px;
            width: 16px;
            fill: #000;
            transition: all 0.4s;
          }
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      &:after {
        content: "";
        border-right: 0.5px solid #ccc;
        height: 14px;
        display: block;
        margin: 0 20px;
      }
    }

    .top-navigation {
      display: flex;
      align-items: center;

      li {
        a {
          font-size: 9.2px;
          color: #000;
          font-weight: 500;
          letter-spacing: 1px;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          transition: all 0.4s;
        }

        &:not(:last-child) {
          margin-right: 20px;
          padding-right: 20px;
          border-right: 0.5px solid #ccc;
        }
      }
    }
  }

  .header-management {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 30px 0;

    .management-logo {
      &.mobile {
        display: none;
      }

      .logo-text {
        font-size: 23px;
        font-weight: 600;
        color: #000;
        letter-spacing: -0.9px;

        span {
          font-weight: 700;
          color: #c31432;
          font-size: 30px;
          line-height: 10px;
        }
      }

      .logo-image {
        width: auto;
        height: auto;
        max-height: 65px;
        max-width: 200px;
      }
    }

    .management-offer {
      position: relative;

      button {
        height: 37px;
        border-radius: 20px;
        border: none;
        padding: 0 20px;
        outline: none;
        display: flex;
        align-items: center;
        .small-letters(#fff);

        svg {
          margin-left: 10px;
          fill: #fff;
          height: 6px;
          min-width: 6px;
          max-width: 6px;
          transform: rotate(90deg);
        }
      }

      .offer-dropdown {
        position: absolute;
        min-width: 310px;
        z-index: -1;
        bottom: 0;
        left: 0;
        transform: translate(0, 100%);
        padding-top: 20px;
        opacity: 0;
        visibility: hidden;

        .offer-dropdown-inner {
          border-top-width: 2px;
          border-top-style: solid;
          padding: 10px 0 10px 10px;
          background: #fff;
          box-shadow: 5px 8px 32px 0px rgba(0, 0, 0, 0.18);

          .dropdown-item {
            position: relative;
            padding: 0;
            background: none;

            .item-heading {
              padding: 15px 40px 15px 18px;
              background: none;
              font-size: 17px;
              display: block;
              color: #000;
              letter-spacing: 1px;
              border-right: 10px solid #fff;
              font-weight: 500;
              line-height: 13px;
              transition: all 0.4s;
            }

            .dropdown-menu-inner {
              position: absolute;
              right: 0;
              top: 0;
              transform: translateX(100%);
              padding: 10px;
              background: #fff;
              z-index: 444;
              box-shadow: 15px 8px 20px 0px rgba(0, 0, 0, 0.13);
              opacity: 0;
              visibility: hidden;

              .menu-item {
                display: block;
                font-size: 14px;
                color: #000;
                padding: 10px;
                letter-spacing: 0.7px;
                font-weight: 500;
                line-height: 13px;
              }
            }

            &.active,
            &:hover {
              background: #f3f3f3;
            }

            &.active {
              .dropdown-menu-inner {
                opacity: 1;
                visibility: visible;
              }
            }

            &.has-child {
              .item-heading {
                &:after {
                  content: "";
                  height: 6px;
                  width: 6px;
                  background: url("../img/icons/right-chevron.svg") center;
                  background-size: cover;
                  opacity: 0.5;
                  position: absolute;
                  right: 20px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }

        &.active {
          opacity: 1;
          visibility: visible;
          z-index: 333;
        }
      }
    }

    .management-breakpoint {
      display: none;
    }

    .management-search-engine {
      border: 0.5px solid #000;
      height: 40px;
      border-radius: 50px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.4s;

      input {
        width: 100%;
        min-width: 470px;
        height: 40px;
        padding: 10px 15px 10px 15px;
        border: none;
        background: none;
        font-size: 11px;
        color: #000;
        font-weight: 500;
        letter-spacing: 1px;
        margin: 0;
        transition: all 0.4s;

        &:focus {
          outline: none;
        }

        &::placeholder {
          font-size: 10px;
          color: #000;
          opacity: 0.5;
          font-weight: 500;
          letter-spacing: 1px;
        }
      }

      .btn-search {
        height: 40px;
        min-width: 40px;
        background: #000;
        border: none;
        border-radius: 50%;
        font-size: 14px;
        color: #fff;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(1px);
        transition: background 0.4s;

        svg {
          height: 15px;
          width: 15px;
          fill: #fff;
        }

        &:hover {
          background: darken(#000, 10%);
        }
      }
    }

    .management-service {
      display: flex;
      align-items: center;

      .service-icons {
        a,
        button {
          padding: 0;
          border: none;
          background: none;
          outline: none;
          display: flex;
          align-items: center;
          transition: all 0.4s;
          position: relative;

          svg {
            height: 20px;
            width: 20px;
            fill: #444;
            transition: all 0.4s;
          }

          .quantity-badge {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 17px;
            width: 17px;
            border-radius: 50%;
            font-size: 8px;
            font-weight: 500;
            color: #fff;
            top: -7px;
            left: 15px;

            &.pulse-badge {
              &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                transform: scale(0.8);
                display: block;
                width: 20px;
                height: 20px;
                background-color: @color1;
                filter: blur(2px);
                border-radius: 15px;
                animation: pulse-badge 3s
                  cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
              }
            }

            @keyframes pulse-badge {
              40% {
                transform: scale(0.8);
                opacity: 0;
              }
              50% {
                opacity: 0.6;
                transform: scale(1);
              }
              60% {
                opacity: 0;
                transform: scale(2);
              }
              100% {
                opacity: 0;
                transform: scale(2);
              }
            }
          }

          .cost-line {
            align-self: flex-end;
            transform: translateY(3px);
            font-size: 11px;
            margin-left: 15px;
            color: #444;

            small {
              letter-spacing: 0;
              font-size: 11px;
            }
          }

          &:hover {
            color: #000;

            svg {
              fill: #000;
            }
          }
        }

        &:nth-child(2) {
          margin-left: 20px;

          a {
            .quantity-badge {
              left: 13px;
            }
          }
        }
      }

      .service-nav-button {
        display: none;

        .nav-button {
          transform: translateY(2px);
          margin-right: -10px;
          padding: 10px;
          border: none;
          background: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          outline: none;

          .button-bar {
            height: 1.5px;
            width: 25px;
            background: #000;
            border-radius: 10px;
            transition: all 0.4s;

            &:nth-child(2) {
              margin-top: 5px;
            }

            &:nth-child(3) {
              margin-top: 5px;
            }
          }

          .button-name {
            font-size: 8px;
            letter-spacing: 0.7px;
            margin-top: 5px;
            display: block;
            font-weight: 500;
            color: #000;
            line-height: 8px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

section.section-welcome {
  transition: all 0.4s;

  &.no-js {
    display: none;
  }

  .welcome-item {
    background: #f3f3f3;
    display: flex;
    justify-content: space-between;

    .item-image {
      width: 43%;
      height: 570px;
      position: relative;
      z-index: 111;

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: contain;
        transition: all 0.4s;

        &.cover {
          object-fit: cover;
        }
      }
    }

    .item-description {
      width: 50%;
      align-self: center;
      transition: all 0.4s;
      position: relative;
      color: #000;

      &:before {
        content: "";
        top: 50%;
        transform: translateY(-50%);
        height: 300px;
        width: 300px;
        left: -240px;
        display: block;
        position: absolute;
        border-radius: 50%;
        background: #f9f9f9;
      }

      .info {
        position: relative;
        font-size: 9.2px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      .text {
        font-size: 39px;
        line-height: 45px;
        max-width: 400px;
        margin-bottom: 20px;
        position: relative;
      }

      .description {
        position: relative;
        font-size: 14px;
        font-weight: 400;
        max-width: 400px;
        line-height: 24px;
        opacity: 0.7;
        margin-bottom: 40px;
      }

      &.white {
        color: #fff;
      }
    }

    &.full-width-background {
      height: 570px;
      position: relative;

      .item-image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: auto;
      }

      .item-description {
        width: 100%;
        position: relative;
        text-align: center;

        &:before {
          content: none;
        }

        .text {
          margin: 0 auto 20px auto;
        }

        .description {
          margin: 0 auto 40px auto;
        }

        .btn {
          background: #f3f3f3;
          padding: 5px 12px;
          border-radius: 15px;

          &.pulse-animate {
            &:after {
              left: 12px;
              top: 5px;
            }
          }
        }
      }
    }
  }

  .owl-carousel-welcome {
    position: relative;

    .welcome-item {
      .item-image {
        img {
          &.position-animate {
            opacity: 0;
          }
        }
      }

      .item-description {
        &.position-animate {
          transform: translateX(20px);
          opacity: 0;
        }
      }
    }

    .owl-dots {
      position: absolute !important;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;

      .owl-dot {
        margin: 5px;
        height: 17px;
        width: 17px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        transition: all 0.4s;
        outline: none;

        span {
          background-color: #222;
          min-width: 6px;
          max-width: 6px;
          height: 6px;
          border-radius: 50%;
          margin: 0;
          transition: all 0.4s;
        }

        &.active {
          border: 1px solid #000;
          background: #fff;

          span {
            background-color: #000;
          }
        }
      }
    }

    .owl-nav {
      position: absolute !important;
      margin-top: 0;
      bottom: 20px;
      right: 20px;
      display: flex;
      align-items: center;

      .owl-prev,
      .owl-next {
        background-color: #f3f3f3;
        background-image: url(../img/icons/right-chevron.svg);
        background-position: center;
        background-size: 45%;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        display: block;
        height: 30px;
        width: 30px;
        outline: none;
        border-radius: 2px;
      }

      .owl-prev {
        transform: rotate(-180deg);
        margin-right: 5px;
      }
    }
  }

  .welcome-mobile-item {
    display: none;

    img {
      width: 100%;
    }
  }

  .owl-carousel-welcome-mobile {
    display: none;
  }
}

section.section-advantages {
  margin-top: 100px;
  transition: all 0.4s;

  .advantages-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-service {
      text-align: center;
      width: 25%;
      padding: 0 10px;

      .service-icon {
        height: 40px;
        width: 40px;
      }

      .service-name {
        margin-top: 10px;
        .small-letters(#000);
        text-transform: none;
      }
    }
  }
}

section.section-offer {
  margin-top: 100px;
  transition: all 0.4s;

  .section-heading {
    text-align: center;

    h2 {
      font-size: 34px;
      font-weight: 400;
      color: #000;
    }

    p {
      margin-top: 10px;
      opacity: 0.7;
      color: #000;
      font-size: 13px;
      letter-spacing: 0.3px;
    }
  }

  .nav-tabs {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: none;

    .nav-item {
      .nav-link {
        padding: 0;
        margin: 5px 10px;
        border: none;
        border-radius: 0;
        background: none;
        color: #000 !important;
        opacity: 0.5;
        transition: 0.4s;
        .small-letters(auto);
        font-size: 14px;
        text-transform: none;

        &.active {
          color: inherit !important;
          opacity: 1;
        }
      }
    }
  }

  .products-flexbox {
    margin-top: 10px;
  }
}

section.section-cta {
  margin-top: 100px;
  transition: all 0.4s;

  &.no-js {
    display: none;
  }

  .cta-item {
    position: relative;

    .item-image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: contain;
        transition: all 0.4s;

        &.cover {
          object-fit: cover;
        }
      }
    }

    .item-description {
      padding: 70px 40px;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 400px;
      color: #000;

      .info {
        position: relative;
        font-size: 9.2px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }

      .text {
        font-size: 39px;
        line-height: 45px;
        max-width: 520px;
        margin: 0 auto 20px auto;
        position: relative;
      }

      .description {
        position: relative;
        font-size: 14px;
        font-weight: 400;
        max-width: 400px;
        line-height: 24px;
        opacity: 0.85;
        margin: 0 auto 40px auto;
      }

      .btn {
        background: #f3f3f3;
        padding: 5px 12px;
        border-radius: 15px;

        &.pulse-animate {
          &:after {
            left: 12px;
            top: 5px;
          }
        }
      }

      &.white {
        color: #fff;
      }
    }
  }

  .owl-carousel-cta {
    background: #f3f3f3;
    position: relative;

    .owl-dots {
      display: none;
    }

    .owl-nav {
      position: absolute !important;
      margin-top: 0;
      bottom: 17px;
      right: 20px;
      display: flex;
      align-items: center;

      .owl-prev,
      .owl-next {
        background-color: #f3f3f3;
        background-image: url(../img/icons/right-chevron.svg);
        background-position: center;
        background-size: 45%;
        background-repeat: no-repeat;
        padding: 0;
        margin: 0;
        display: block;
        height: 30px;
        width: 30px;
        outline: none;
        border-radius: 2px;
      }

      .owl-prev {
        transform: rotate(-180deg);
        margin-right: 5px;
      }
    }
  }
}

section.section-blog {
  margin-top: 100px;
  transition: all 0.4s;

  &.subpage {
    background: #fff;
    margin-top: 0;
    padding: 70px 0 100px 0;
  }

  .section-heading {
    text-align: center;

    h1,
    h2 {
      font-size: 34px;
      font-weight: 400;
      color: #000;

      a {
        color: inherit;
      }
    }

    p {
      margin-top: 10px;
      opacity: 0.7;
      color: #000;
      font-size: 13px;
      letter-spacing: 0.3px;
    }
  }
}

section.section-industries {
  margin-top: 100px;
  transition: all 0.4s;

  .section-heading {
    text-align: center;

    h2 {
      font-size: 34px;
      font-weight: 400;
      color: #000;

      a {
        color: inherit;
      }
    }

    p {
      margin-top: 10px;
      opacity: 0.7;
      color: #000;
      font-size: 13px;
      letter-spacing: 0.3px;
    }
  }

  .industries-flexbox {
    max-width: 1100px;
    margin: 25px auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .flexbox-service {
      margin: 10px;
      padding: 10px 20px;
      background: #f3f3f3;
      color: #000;
      border-radius: 2px;
      font-size: 16px;

      &:hover {
        color: #fff;
      }
    }
  }
}

section.section-newsletter {
  margin-top: 100px;
  padding: 100px 0;
  background: #fff;
  transition: all 0.4s;

  &.subpage {
    margin-top: 0;
    padding-top: 0;
  }

  .section-heading {
    text-align: center;

    h2 {
      font-size: 34px;
      font-weight: 400;
      color: #000;

      a {
        color: inherit;
      }
    }

    p {
      margin-top: 10px;
      opacity: 0.7;
      color: #000;
      font-size: 13px;
      letter-spacing: 0.3px;
    }
  }

  .newsletter-input {
    border: 0.5px solid #000;
    height: 40px;
    width: 580px;
    margin: 50px auto 0 auto;
    max-width: 100%;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s;

    input {
      width: 100%;
      height: 40px;
      padding: 10px 15px 10px 15px;
      border: none;
      background: none;
      font-size: 11px;
      color: #000;
      font-weight: 500;
      letter-spacing: 1px;
      margin: 0;
      transition: all 0.4s;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-size: 9.2px;
        color: #000;
        opacity: 0.5;
        font-weight: 500;
        letter-spacing: 1px;
      }
    }

    .btn-search {
      height: 40px;
      min-width: 40px;
      .small-letters(#fff);
      padding: 0 30px;
      white-space: nowrap;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateX(1px);
      transition: background 0.4s;

      &:hover {
        background: darken(#000, 10%);
      }
    }
  }

  .checkbox-wrapper-outer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}

section.kategoria-section-content {
  transition: all 0.4s;
  padding: 70px 0 100px 0;
  background: #fff;

  .content-flexbox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .flexbox-sidebar {
      width: 20%;
      position: relative;

      .heading {
        margin-bottom: 25px;

        p {
          .small-letters(#000);
          font-weight: 400;
          font-size: 11px;
        }
      }
    }

    .flexbox-body {
      width: 75%;

      .body-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .heading-categories-button {
          display: none;
          align-items: center;
          height: 29px;
          border-radius: 30px;
          border: none;
          outline: none;
          padding: 0 18px;

          svg {
            fill: #fff;
            height: 14px;
            min-width: 14px;
            max-width: 14px;
            margin-right: 10px;
          }

          span {
            .small-letters(#fff);
          }
        }

        .heading-text {
          .small-letters(#000);
          font-weight: 400;
          align-self: flex-start;
          font-size: 11px;
        }

        .inner-wrapper {
          display: flex;
          align-items: center;

          .heading-grid-wrapper {
            position: relative;
            margin-left: 30px;

            &:before {
              content: "";
              height: 100%;
              width: 1px;
              position: absolute;
              left: 50%;
              top: 0;
              z-index: 999;
              transform: translateX(-50%);
              border-left: 1px solid #999;
            }

            .heading-grid {
              border-radius: 30px;
              display: flex;
              border: 1px solid #999;
              overflow: hidden;
              height: 29px;

              a,
              button {
                margin: 0;
                padding: 0;
                border: none;
                background: #fff;
                outline: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translateY(-1px);
                height: 29px;
                width: 35px;
                transition: all 0.4s;

                svg {
                  width: 15px;
                  height: 15px;
                  fill: #999;
                  transition: all 0.4s;
                }

                &:hover {
                  svg {
                    fill: #000;
                  }
                }

                &:nth-child(1) {
                  margin-left: 5px;
                }

                &:nth-child(2) {
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }

      .heading {
        display: none;

        .heading-backlink {
          display: flex;
          align-items: center;
          padding: 12px 0;
          border-bottom: 0.5px solid #ccc;

          span {
            font-size: 17px;
            font-weight: 500;
            color: #666;
          }

          img {
            margin-right: 10px;
            transform: rotate(180deg);
            height: 10px;
          }
        }

        .heading-name {
          padding: 25px 0;
          font-size: 22px;
          font-weight: 500;
          color: #000;
          line-height: 25px;
        }
      }

      .btn-more-products-wrapper {
        margin-top: 80px;

        .btn-more-products {
          .small-letters(#000);
          padding: 10px 30px;
          border: 1px solid #000;
          border-radius: 2px;
          outline: none;
          background: none;
          transition: all 0.4s;

          &:hover {
            background: #f3f3f3;
          }
        }
      }
    }
  }

  .content-description {
    border-top: 0.5px solid #ccc;
    padding-top: 90px;
    margin-top: 100px;

    .description-inner {
      text-align: center;
      max-width: 800px;
      margin: 0 auto;

      h1,
      h2 {
        font-size: 34px;
        font-weight: 400;
        color: #000;

        a {
          color: inherit;
        }
      }

      p {
        margin-top: 10px;
        opacity: 0.7;
        color: #000;
        font-size: 13px;
        letter-spacing: 0.3px;
      }
    }
  }
}

.category-nav-wrapper {
  position: relative;

  .category-nav {
    li {
      list-style: none;

      a {
        font-size: 17px;
        display: block;
        color: #000;
        letter-spacing: 1px;
        font-weight: 400;
        line-height: 13px;
        transition: all 0.4s;
      }

      &.active {
        > a {
          font-weight: 700;
          color: @color2;
        }
      }

      &:not(:last-child) {
        padding-bottom: 20px;
      }

      ul {
        margin-top: 17px;
        padding-left: 17px;

        li {
          padding-bottom: 0 !important;

          a {
            padding: 5px 0;
            font-size: 14px;
            letter-spacing: 0.7px;
            color: #000;
          }

          ul {
            margin-top: 14px;
          }
        }
      }
    }
  }

  .filter-nav {
    margin-top: 65px;

    .heading {
      &:not(:first-of-type) {
        margin-top: 65px;
      }
    }

    .checkbox-wrapper {
      .radio-box {
        font-size: 17px;
        letter-spacing: 1px;

        .box-square {
          margin-top: 7px;
        }
      }
    }

    .range-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input[type="number"] {
        border: none;
        max-width: 100px;
        margin: 0 10px;
        outline: none;
        text-align: center;
        font-size: 14px;
        height: 30px;
        border-radius: 2px;
        background: #f3f3f3;
        width: 100%;
        -moz-appearance: textfield;
      }

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      input[type="number"]:invalid,
      input[type="number"]:out-of-range {
        border: 1px solid #ff6347;
      }

      span {
        display: block;
        min-width: 12px;
      }

      p {
        font-size: 13px;
        color: #000;
      }
    }

    .buttons-wrapper {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn-clear {
        .small-letters(#000);
        font-size: 12px;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        padding: 0;
      }

      .btn-filter {
        padding: 0;
        border: none;
        .small-letters(#fff);
        font-size: 12px;
        border-radius: 2px;
        padding: 3px 10px;
        outline: none;
        cursor: pointer;
        text-transform: uppercase;
        transition: all 0.4s;
      }
    }
  }
}

section.produkt-section-content {
  transition: all 0.4s;
  padding: 70px 0 100px 0;
  background: #fff;

  .heading {
    display: none;

    .heading-backlink {
      display: flex;
      align-items: center;
      padding: 12px 0;
      border-bottom: 0.5px solid #ccc;

      span {
        font-size: 17px;
        font-weight: 500;
        color: #666;
      }

      img {
        margin-right: 10px;
        transform: rotate(180deg);
        height: 10px;
      }
    }
  }

  .content-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .product-gallery {
      width: 38%;

      img {
        width: 100%;
      }

      .lSSlideWrapper {
        position: relative;
      }

      .lSAction {
        a {
          margin-top: 0;
          transition: all 0.4s;
          background: none;
        }

        .lSPrev {
          width: 25px;
          height: 100%;
          left: 0;
          top: 0;
          position: absolute;
          transform: none;

          &:after {
            content: "";
            display: block;
            width: 25px;
            height: 25px;
            background: url("../library/lightslider-master/dist/img/right-chevron.svg")
              center;
            background-size: cover;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%) rotate(180deg);
          }
        }

        .lSNext {
          width: 25px;
          height: 100%;
          right: 0;
          top: 0;
          position: absolute;
          transform: none;

          &:after {
            content: "";
            display: block;
            width: 25px;
            height: 25px;
            background: url("../library/lightslider-master/dist/img/right-chevron.svg")
              center;
            background-size: cover;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }

      .lSGallery {
        margin-top: 23px !important;

        li {
          border-radius: 0;
          opacity: 0.6;
          transition: all 0.4s;

          a {
            width: 100%;
            padding-top: 100%;
            display: block;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              object-position: center;
              object-fit: cover;
            }
          }

          &.active,
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    .product-info {
      width: 55%;

      .badge-wrapper {
        display: flex;
        margin-bottom: 20px;

        .badge {
          width: 90px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-size: 10px;
          color: #fff;
          border-radius: 2px;
          margin-bottom: 6px;

          &.promotion {
            background: tomato;
            display: none;
          }

          &.new {
            background: olivedrab;
            display: none;
          }

          &.bestseller {
            background: dodgerblue;
            display: none;
          }

          &.own {
            background: #ef7b0a;
            display: none;
          }

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      h1 {
        max-width: 650px;
        font-size: 28px;
        font-weight: 400;
        color: #000;
      }

      .info-features {
        margin-top: 40px;

        > * {
          &:not(:first-child) {
            margin-top: 10px;
          }
        }

        .feature-inner {
          display: flex;

          p {
            text-transform: uppercase;
            opacity: 0.7;
            color: #000;
            font-size: 13px;
            letter-spacing: 0.3px;

            &:nth-child(2) {
              margin-left: 20px;
              font-weight: 700;
            }
          }
        }

        .select-wrapper-flexbox {
          display: flex;
          align-items: center;

          label {
            opacity: 0.7;
            text-transform: uppercase;
            color: #000;
            font-size: 13px;
            letter-spacing: 0.3px;
            white-space: nowrap;
            margin-bottom: 0;
            margin-right: 20px;
          }
        }
      }

      .info-price {
        margin-top: 40px;

        .price-value {
          color: #000;
          font-size: 28px;
          letter-spacing: -0.5px;
          font-weight: 400;

          small {
            font-size: 28px;
            font-weight: 400;
            color: #999;
            margin-right: 7px;
            position: relative;
            display: none;

            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: 1px;
              width: 100%;
              height: 0.5px;
              background: #666;
            }
          }

          span {
            font-weight: 400;
            color: #999;
            font-size: 18px;
          }
        }
      }

      .info-contact {
        padding-top: 40px;
        margin-top: 40px;
        border-top: 1px solid #e5e5e5;

        h3 {
          font-size: 22px;
          margin-bottom: 10px;
        }

        a {
          display: block;
          color: @color1;
          font-size: 17px;

          &:first-of-type {
            margin-top: 20px;
          }
        }

        p {
          opacity: 0.7;
          color: #000;
          font-size: 13px;
          letter-spacing: 0.3px;
        }
      }

      .info-add {
        margin-top: 30px;
        display: flex;
        align-items: center;

        .service-quantity {
          display: flex;
          align-self: center;

          .input-number {
            width: 70px;
            padding: 0;
            margin: 0;
            text-align: center;
            outline: none;
            background: none;
            border-radius: 0;
            border: none;
          }

          .input-number,
          .input-number-decrement,
          .input-number-increment {
            height: 40px;
            user-select: none;
          }

          .input-number-decrement,
          .input-number-increment {
            min-width: 40px;
            border-radius: 2px;
            background: #f3f3f3;
            color: #000;
            text-align: center;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: background 0.4s;
            cursor: pointer;
            border: none;
            outline: none;

            &:active {
              background: #000;
              color: #fff;
            }
          }

          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            margin: 0;
          }

          input[type="number"] {
            -moz-appearance: textfield;
          }
        }

        .btn-fill {
          margin-left: 30px;
        }
      }

      .download-list {
        margin-top: 20px;

        li {
          a {
            display: block;
            background: lighten(#efefefef, 3%);
            padding: 8px 10px;
            font-size: 13px;
            border-left: 2px solid transparent;
            color: #333;

            &:hover {
              border-color: @color1;
              color: @color1;
            }
          }

          &.heading {
            margin-top: 0;
            text-align: left;
          }

          &:nth-child(even) {
            a {
              background: #efefef;
            }
          }
        }
      }

      .info-description {
        padding-top: 40px;
        margin-top: 40px;
        border-top: 1px solid #e5e5e5;

        h2 {
          text-transform: uppercase;
          opacity: 0.7;
          color: #000;
          font-size: 13px;
          font-weight: 700;
          letter-spacing: 0.3px;
          margin-bottom: 25px;
        }

        p,
        li {
          opacity: 0.7;
          color: #000;
          font-size: 13px;
          letter-spacing: 0.3px;
        }

        ul {
          padding-left: 17px;
        }
      }

      &.promotion {
        .promotion {
          display: flex !important;
        }

        .price-value {
          color: tomato;

          small {
            display: inline-block;
          }
        }
      }

      &.new {
        .new {
          display: flex !important;
        }
      }

      &.bestseller {
        .bestseller {
          display: flex !important;
        }
      }

      &.own {
        .own {
          display: flex !important;
        }
      }
    }
  }
}

section.static-page-section-content {
  padding: 100px 0;
  background: #fff;
  transition: all 0.4s;

  .content-inner {
    max-width: 770px;
    width: 100%;
    margin: 0 auto;
  }

  .backlink {
    display: inline-block;
    margin-bottom: 30px;

    .backlink-inner {
      display: flex;
      align-items: center;

      span {
        .small-letters (#000);
      }

      img {
        margin-right: 15px;
        transform: rotate(180deg);
        height: 8px;
      }
    }
  }

  .heading {
    font-size: 32px;
    letter-spacing: 1px;
    color: #000;
    font-weight: 400;
  }

  .inner-info {
    margin-top: 35px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .info-service {
      .small-letters(#000);
      margin-right: 30px;
    }
  }

  .inner-short-description {
    margin-top: 35px;
    padding-top: 35px;
    border-top: 0.5px solid #ccc;

    p {
      color: #000;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0.3px;
    }
  }

  .inner-main-image {
    margin-top: 35px;
    height: 400px;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .inner-gallery {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .gallery-service {
      margin-top: calc(~"4% / 3");
      display: block;
      width: 24%;
      padding-top: 20%;
      position: relative;
      overflow: hidden;
      background: #f9f9f9;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        top: 50%;
        left: 0;
        transform: translateY(-50%;);
      }

      &.service-hidden {
        margin-top: 0;
        padding-top: 0;
        opacity: 0;
        visibility: hidden;
      }
    }

    &.gallery-collapsed {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .btn-gallery-more {
    margin-top: 30px;
    text-transform: none;

    &.hidden {
      display: none;
    }
  }

  .inner-description {
    margin-top: 35px;

    p,
    li {
      color: #000;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0.3px;
    }

    ol,
    ul {
      padding-left: 17px;
    }

    h1 {
      font-size: 32px;
      color: #000;
      font-weight: 400;
    }

    h2 {
      font-size: 28px;
      color: #000;
      font-weight: 400;
    }

    h3 {
      font-size: 24px;
      color: #000;
      font-weight: 400;
    }
  }

  .inner-accordion {
    margin: 60px 0;
    display: flex;
    justify-content: space-between;

    .accordion {
      width: 50%;

      .accordion-item {
        margin: 5px 0;
      }

      .accordion-button {
        display: block;
        border-radius: 0;
        background-color: #f3f3f3;
        border: none;
        padding: 10px;
        text-align: left;
        color: @color1;
      }

      .accordion-collapse {
        border: none;

        .accordion-body {
          padding: 30px 20px;
          color: #000;
          font-size: 14px;
          line-height: 25px;
          letter-spacing: 0.3px;
          opacity: 0.7;
        }
      }
    }

    .accordion-text {
      width: 45%;

      p {
        color: #000;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.3px;
      }
    }
  }
}

section.faq-section-content {
  padding: 100px 0;
  background: #fff;
  transition: all 0.4s;

  .content-inner {
    max-width: 770px;
    width: 100%;
    margin: 0 auto;

    .heading {
      text-align: center;

      h1 {
        font-size: 50px;
        font-weight: 400;
        color: #000;
      }

      p {
        margin-top: 10px;
        opacity: 0.7;
        color: #000;
        font-size: 13px;
        letter-spacing: 0.3px;
      }
    }

    .accordion {
      margin-top: 60px;

      .accordion-item {
        .accordion-header {
          button {
            font-size: 18px;
            font-weight: 400;
            color: #000;
            box-shadow: none;
          }
        }

        .accordion-body {
          p,
          li {
            color: #000;
            font-size: 14px;
            line-height: 25px;
            letter-spacing: 0.3px;
          }

          ol,
          ul {
            padding-left: 17px;
          }
        }
      }
    }
  }
}

section.kontakt-section-content {
  transition: all 0.4s;
  padding: 100px 0;
  background: #fff;

  .content-inner {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .inner-contacts {
      width: 40%;

      h1 {
        font-size: 48px;
        font-weight: 400;
        color: #000;

        span {
          font-size: 34px;
          opacity: 0.5;
          padding-left: 65px;
        }
      }

      address {
        margin-top: 50px;
        padding-left: 65px;
        margin-bottom: 0;

        .address-service {
          display: inline-block;

          .service-inner {
            display: flex;
            align-items: center;

            svg {
              height: 25px;
              width: 25px;
              margin-right: 12px;
            }

            span {
              font-size: 25px;
              font-weight: 700;
            }
          }
        }
      }

      .contacts-social-media {
        margin-top: 30px;
        margin-left: 65px;
        display: flex;
        align-items: center;

        li {
          &.heading {
            .small-letters(#000);
            opacity: 0.7;
          }

          a {
            svg {
              height: 18px;
              width: 18px;
              fill: #000;
              transition: all 0.4s;
            }
          }

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }

    .inner-company-data {
      width: 40%;
      background: #f9f9f9;
      border-radius: 5px;
      padding: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      address {
        margin-bottom: 0;
        font-size: 17px;
        font-weight: 300;
        color: #000;

        span {
          font-weight: 600;
          font-size: 21px;
        }
      }
    }
  }

  .content-map {
    margin-top: 80px;
    padding: 0 5% 0 calc(~"5% + 65px");

    .map-inner {
      width: 100%;
      padding-top: 50%;
      position: relative;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 5px;
      }
    }
  }

  .content-others {
    margin-top: 70px;
    padding: 0 5% 0 calc(~"5% + 65px");

    .heading {
      text-align: center;
      font-size: 34px;
      font-weight: 400;
      color: #000;
      margin-bottom: 50px;
    }

    .others-inner {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .inner-service {
        margin: 1.5%;
        padding: 30px 20px 20px 20px;
        border-radius: 2px;
        border: 0.5px solid #ccc;
        position: relative;
        text-align: center;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 30%;

        * + * {
          margin-top: 10px;
        }

        .service-top {
          height: 25px;
          width: 25px;
          position: absolute;
          top: -12px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 50%;
        }

        .service-iframe {
          position: relative;
          width: 100%;
          padding-top: 50%;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border-radius: 2px;
          }
        }

        .service-iframe + .service-name {
          margin-top: 15px;
        }

        .service-image {
          height: 70px;
          width: 70px;
          margin: 0 auto;
          border-radius: 50%;
          overflow: hidden;

          img {
            object-position: center;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }

        .service-name {
          color: #000;
        }

        .service-job {
          color: #000;
          font-size: 11px;
          text-transform: uppercase;
          opacity: 0.7;
        }

        .service-address {
          color: #000;
          font-size: 14px;
          font-weight: 300;
        }

        .service-name + .service-job {
          margin-top: 3px;
        }

        address {
          margin-top: 20px;
          padding-top: 20px;
          border-top: 0.5px solid #ddd;
          margin-bottom: 0;
          position: relative;

          &:after {
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            right: -20px;
            width: 40px;
            background: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
            );
          }

          a {
            margin-top: 0 !important;
            display: block;
          }
        }
      }
    }
  }
}

section.section-footer {
  margin-top: 80px;
  transition: all 0.4s;

  .footer-inner {
    display: flex;

    .inner-service {
      ul {
        .heading {
          white-space: nowrap;
          .small-letters(#000);
          margin-bottom: 20px;
        }

        li {
          a {
            font-size: 14px;
            color: #000;
            white-space: nowrap;
            transition: all 0.4s;
          }
        }

        li + li {
          margin-top: 5px;
        }
      }

      .social-media {
        display: flex;
        align-items: center;

        li {
          a {
            svg {
              height: 18px;
              width: 18px;
              fill: #000;
              transition: all 0.4s;
            }
          }

          & + li {
            margin-top: 0;
          }

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      address {
        margin-bottom: 0;

        a {
          display: block;
          font-size: 16px;
          color: #000;
          transition: all 0.5s;
        }

        p {
          margin-top: 15px;
          font-size: 11px;
          color: #000;
        }
      }

      & + .inner-service {
        margin-left: 125px;
      }
    }
  }

  .footer-credits {
    margin-top: 60px;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ccc;

    .credits-copyright {
      display: flex;
      align-items: center;

      .copyright-logo {
        .logo-text {
          font-size: 23px;
          font-weight: 600;
          color: #000;
          letter-spacing: -0.9px;

          span {
            font-weight: 700;
            color: #c31432;
            font-size: 30px;
            line-height: 10px;
          }
        }
      }

      .divider {
        border-right: 1px solid #ccc;
        width: 1px;
        height: 30px;
        margin: 0 20px;
      }

      p {
        font-size: 14px;
        color: #000;

        a {
          color: inherit;
          transition: all 0.5s;
        }
      }
    }

    .credits-payment {
      ul {
        display: flex;
        align-items: center;

        li {
          img {
            height: 15px;
          }
        }

        li + li {
          margin-left: 15px;
        }
      }
    }
  }

  &.active {
    transform: translateX(-620px);
    filter: blur(5px);
  }
}

.cookies-alert {
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: 888;
  width: 330px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  padding: 20px 30px;
  transition: all 0.4s;

  .btn-close {
    position: absolute;
    top: 4px;
    right: 0;
    outline: none;
    box-shadow: none;
    height: auto;
    width: auto;
    transition: opacity 0.4s;
    border: none;
    background: none;
    position: absolute;
    background: none;
    padding: 10px;
    cursor: pointer;

    span {
      position: absolute;
      display: block;
      left: 0;
      top: 50%;
      width: 12px;
      height: 1px;
      background: #fff;

      &:nth-child(1) {
        transform: rotate(45deg) translateY(-50%);
      }

      &:nth-child(2) {
        transform: rotate(-45deg) translateY(-50%);
      }
    }

    &:after {
      content: none;
    }
  }

  p {
    font-size: 11px;
    font-weight: 300;
    color: #fff;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}
